import { PaginationResponseBase } from '../interfaces/ApiResponses';
import {
  ApiResponse,
  IHopDongResponseBase,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export class HopDongService {
  static fetchContractDetail(id: number): Promise<{
    data: ApiResponse<IHopDongResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/hop-dong/chi-tiet/${id}`
    );
  }

  static fetchAllContracts(
    current_page = 1,
    per_page = 200,
    searchTerm = '',
  ): Promise<{
    data: ApiResponse<PaginationResponseBase<IHopDongResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/hop-dong/danh-sach',
      {
        params: {
          page: current_page,
          ...(searchTerm && {
            s: searchTerm,
          }),
        },
        headers: {
          perpage: per_page,
        }
      }
    );
  }

  static addNewContract(
    contractNumber: string,
    signDay: string,
    expiryDate: string,
    partnerName: string,
    phoneNumber: string,
    address: string,
    taxNumber: string,
    accountId: number,
    maChiTiet: string,
    loai_hop_dong: number,
    so_hoa_don: string,
  ): Promise<{
    data: ApiResponse<IHopDongResponseBase>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    if (contractNumber) {
      bodyFormData.append("hopDong[so_hop_dong]", contractNumber);
    }
    bodyFormData.append("hopDong[ngay_ky]", signDay);

    bodyFormData.append("hopDong[ngay_het_han]", expiryDate);

    bodyFormData.append("hopDong[ten_doi_tac]", partnerName);

    if (phoneNumber) {
      bodyFormData.append("hopDong[so_dien_thoai]", phoneNumber);
    }

    bodyFormData.append("hopDong[dia_chi]", address);

    bodyFormData.append("hopDong[ma_so_thue]", taxNumber);

    bodyFormData.append("hopDong[ma_ct]", maChiTiet);

    bodyFormData.append("hopDong[loai_hop_dong]", loai_hop_dong.toString());

    bodyFormData.append("hopDong[ma_tk]", accountId.toString());

    bodyFormData.append("hopDong[so_hoa_don]", so_hoa_don);

    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/hop-dong/tao-moi",
      bodyFormData
    );
  }

  static updateContract(
    contractId: number,
    contractNumber: string,
    signDay: string,
    expiryDate: string,
    partnerName: string,
    phoneNumber: string,
    address: string,
    taxNumber: string,
    accountId: number,
    maChiTiet: string,
    so_hoa_don: string,
  ): Promise<{
    data: ApiResponse<IHopDongResponseBase>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append("hopDong[so_hop_dong]", contractNumber);

    bodyFormData.append("hopDong[ngay_ky]", signDay);

    bodyFormData.append("hopDong[ngay_het_han]", expiryDate);

    bodyFormData.append("hopDong[ten_doi_tac]", partnerName);

    bodyFormData.append("hopDong[so_dien_thoai]", phoneNumber);

    bodyFormData.append("hopDong[dia_chi]", address);

    bodyFormData.append("hopDong[ma_so_thue]", taxNumber);

    bodyFormData.append("hopDong[ma_ct]", taxNumber);

    bodyFormData.append("hopDong[ma_tk]", accountId.toString());

    bodyFormData.append("hopDong[ma_ct]", maChiTiet.toString());

    bodyFormData.append("hopDong[so_hoa_don]", so_hoa_don);
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/hop-dong/cap-nhat/${contractId}`,
      bodyFormData
    );
  }
}
