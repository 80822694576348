import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "text-center fs-6 fw-bolder mb-1",
  style: {"font-size":"0.7rem"}
}
const _hoisted_2 = {
  class: "text-center",
  style: {"font-size":"0.7rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.nguoiKy), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.thongTin), 1)
  ], 64))
}